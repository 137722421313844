import { useState } from "react";
import { Formik } from "formik";
import { Form as FormikForm, SubmitButton, Input, Select, ResetButton } from "formik-semantic-ui-react";
import { Button, Form, Label, Modal, Segment } from "semantic-ui-react";
import _ from "lodash";

import { useAddPIREP } from '@api/pirep';
import { IcingLevel, IcingType, SkyCover, Turbulence } from "@zkc/shared";

function PIREPForm() {

    const { mutateAsync: submit, isSuccess } = useAddPIREP();
    const initialValues = {};

    return (

        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => await submit(values)}
        >
            {({ isSubmitting }) => (
                <FormikForm size="large">

                    <Form.Field inline>
                        <Label size="big">LOCATION</Label>
                        <Input name='location' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">TIME PHASE</Label>
                        <Input name='time' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">ALTITUDE</Label>
                        <Input name='altitude' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">AIRCRAFT TYPE</Label>
                        <Input name='aircraft' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">TURBULENCE</Label>
                        <Select options={_.map(Turbulence, t => {
                            return {
                                key: t,
                                value: t,
                                text: t,
                            };
                        })} name='turbulence' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">REMARKS</Label>
                        <Input name='remarks' size='large' />
                    </Form.Field>

                    <Segment>ADDITIONAL FIELDS -</Segment>

                    <Form.Field inline>
                        <Label size="big">SKY COVER</Label>
                        <Input name='sky' size='large' />
                        <Select options={_.map(SkyCover, s => {
                            return {
                                key: s,
                                value: s,
                                text: s,
                            };
                        })} name='skycover' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">TEMPERATURE</Label>
                        <Input name='temperature' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">ICING</Label>
                        <Select options={_.map(IcingType, t => {
                            return {
                                key: t,
                                value: t,
                                text: t,
                            };
                        })} name='icingtype' />
                        <Select options={_.map(IcingLevel, t => {
                            return {
                                key: t,
                                value: t,
                                text: t,
                            };
                        })} name='icinglevel' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">WIND</Label>
                        <Input name='wind' size='large' />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">WEATHER</Label>
                        <Input name='weather' size='large' />
                    </Form.Field>

                    <Button.Group>
                        <ResetButton color="red">CLEAR</ResetButton>
                        <SubmitButton color="blue" disabled={isSubmitting}>SUBMIT</SubmitButton>
                    </Button.Group>

                </FormikForm>
            )}
        </Formik>

    )

}

export default function PIREPModal() {

    const [open, setOpen] = useState(false);

    return (

        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button primary>PIREP</Button>}
            size='small'
        >

            <Modal.Content scrolling>
                <PIREPForm />
            </Modal.Content>

        </Modal>

    )

}