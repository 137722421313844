import { Container, Table } from "semantic-ui-react";
import { useAreaInfo } from "@api/areainfo";
import AreaModal from "./AreaModal";
import { useContext, useEffect, useState } from "react";
import { SessionContext } from "./Session";
import _ from "lodash";
import { IAreaInfo } from "@zkc/shared";

type AreaNoteProps = {
    info: IAreaInfo,
};
function AreaNote({ info }: AreaNoteProps) {

    return (
        <Table.Row>
            <Table.Cell>{info.notice}</Table.Cell>
        </Table.Row>
    )

}

export default function AreaNotes() {

    const { facility } = useContext(SessionContext);

    const { data: areainfo } = useAreaInfo();

    const [info, setInfo] = useState<IAreaInfo[]>([]);

    useEffect(() => {

        if(_.isNil(facility) || _.isNil(areainfo)) return;

        let a = _.filter(areainfo, (ai: IAreaInfo) => {
            return ai.facility === facility.shortname;
        });
        setInfo(a);

    }, [facility, areainfo]);

    return (
            
            <Container>
                <Table>

                    <Table.Header fullWidth>

                        <Table.Row>

                            <Table.HeaderCell>
                                <AreaModal />
                            </Table.HeaderCell>

                            <Table.HeaderCell>{facility?.shortname} Area Notes</Table.HeaderCell>

                        </Table.Row>

                    </Table.Header>

                    <Table.Body>
                        {
                            _.map(info, i => {
                                return <AreaNote key={i.id} info={i} />
                            })
                        }
                    </Table.Body>

                </Table>
            </Container>

    )

}