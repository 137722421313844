import { useContext } from 'react';
import { Button, Grid, Tab } from 'semantic-ui-react';
import _ from 'lodash';

import { SessionContext } from '@components/Session';
import NOTAMs from '@components/NOTAMs';
import PIREPs from '@components/PIREPs';
import AreaNotes from '@components/AreaNotes';
import DelayPrograms from '@components/DelayPrograms';
import { LocalATIS, RadarATISList } from '@components/ATIS';
import { LocalMETAR } from '@components/METAR';
import Blank from '@components/Blank';
import { useMETAR } from '@api/weather';
import Outages from '@components/Outages';

function RadarSIA() {

    return (

            <Grid relaxed columns={2}>

                <Grid.Column width={6}>
                    <RadarATISList />
                </Grid.Column>

                <Grid.Column width={10}>

                    <Grid.Row>

                        <Grid columns={2}>

                            <Grid.Row stretched>

                                <Grid.Column>
                                    <AreaNotes />
                                    <DelayPrograms />
                                    <Outages />
                                </Grid.Column>

                                <Grid.Column>
                                    <PIREPs />
                                </Grid.Column>

                            </Grid.Row>

                        </Grid>

                    </Grid.Row>

                    <Grid.Row columns={16}>
                        <NOTAMs />
                    </Grid.Row>

                </Grid.Column>

            </Grid>

    );

}

function TowerSIA({ code }: { code: string }) {

    const { data: metar } = useMETAR(code);

    const panes = [
        {
            menuItem: (
                <Button primary>PIREPs</Button>
            ),
            render: () => <Tab.Pane attached={false}><PIREPs /></Tab.Pane>
        },
        {
            menuItem: (
                <Button primary>NOTAMs</Button>
            ),
            render: () => <Tab.Pane attached={false}><NOTAMs /></Tab.Pane>
        }
    ]

    return (

            <Grid columns={3}>

                <Grid.Row>

                    <Grid.Column width={4}>
                        <LocalATIS airport={code} />
                    </Grid.Column>

                    <Grid.Column width={6}>

                        <Grid columns={2}>

                            <Grid.Row stretched>

                                <Grid.Column stretched width={16}>
                                    <LocalMETAR metar={metar?.raw} />
                                </Grid.Column>

                            </Grid.Row>

                            <Grid.Row columns={2}>

                                <Grid.Column>
                                    <AreaNotes />
                                </Grid.Column>

                                <Grid.Column>
                                    <DelayPrograms />
                                </Grid.Column>

                            </Grid.Row>

                            <Grid.Row columns={2}>

                                <Grid.Column>
                                    <Outages />
                                </Grid.Column>

                            </Grid.Row>

                        </Grid>

                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Tab panes={panes} />
                    </Grid.Column>

                </Grid.Row>

            </Grid>

    )

}

export default function SIA() {

    const { facility } = useContext(SessionContext);

    return (
        !_.isNil(facility) ?
            {
                'enroute': <RadarSIA />,
                'terminal': <RadarSIA />,
                'local': <TowerSIA code={facility?.airports[0].code} />,
            }[facility?.type ? facility.type as string : ''] || null
            : <Blank />
    )

}
