import { useState, useEffect, useContext } from "react";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Button, Container, Dropdown, Header, Icon, Menu } from "semantic-ui-react";

import { socket } from "@api/websocket";
import { useCID, useFacilities } from "@api/facility";
import UTCClock from '@components/UTCClock';
import { getSessionCID, Session, SessionContext } from "@components/Session";
import WeatherBriefing from '@pages/WeatherBriefing';
import IDS from '@pages/IDS';
import SIA from "@pages/SIA";
import Charts from "@pages/Charts";
import Search from "@pages/Search";
import Lookup from "@pages/Lookup";
import Docs from "@pages/Docs";
import WX from "@pages/WX";
import { IFacility } from "@zkc/shared";

export const queryClient = new QueryClient();

const IDSMenu = () => {

    function useStateWithLocalStorage(localStorageKey: string): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {

        const [value, setValue] = useState(Notification.permission === 'granted' ? true : false);

        useEffect(() => {
            localStorage.setItem(localStorageKey, String(value));
        }, [value, localStorageKey]);

        return [value, setValue];

    };

    function setNotificationPermission(value: string) {

        if (!('Notification' in window)) return;
        setShowNotifications(Boolean(value));
        if (!['granted', 'denied'].includes(Notification.permission)) Notification.requestPermission();

    };

    function reset() {

        setFacility(null);
        socket.disconnect();
        queryClient.clear();
        navigate('/');

    }

    function selectFacility(facility: IFacility) {

        setFacility(facility);
        socket.connect();
        socket.emit('signin', cid);

    }

    const navigate = useNavigate();
    const { data: facilities } = useFacilities();
    const { facility, setFacility }: { facility: IFacility | null, setFacility: Function } = useContext(SessionContext);
    const { data: cid } = useCID();
    const [showNotifications, setShowNotifications] = useStateWithLocalStorage('allowPushNotifications');

    return (
        <Menu fixed="top" borderless>
            <Container fluid>

            <Menu.Menu position="left">

                <Menu.Item>
                    <Link to='/'>
                        <Icon name='home' />
                    </Link>
                </Menu.Item>

                {
                    _.isNil(cid) ?
                        null :
                        <Dropdown scrolling item text={(_.isNil(facility) ? `Select Facility` : facility.shortname)}>
                            <Dropdown.Menu>

                                <Dropdown.Header content='Enroute' />
                                <Dropdown.Divider />
                                {
                                    _.map(_.filter(facilities, { type: 'enroute' }), (f, i) => {
                                        return (
                                            <Dropdown.Item
                                                className="facilityOption"
                                                onClick={() => selectFacility(f)}
                                                as={Link}
                                                to={`/ids`}
                                                key={i}>
                                                {f.shortname}
                                            </Dropdown.Item>
                                        )
                                    })
                                }

                                <Dropdown.Header content='Terminal' />
                                <Dropdown.Divider />
                                {
                                    _.map(_.filter(facilities, { type: 'terminal' }), (f, i) => {
                                        return (
                                            <Dropdown.Item
                                                className="facilityOption"
                                                onClick={() => selectFacility(f)}
                                                as={Link}
                                                to={`/ids`}
                                                key={i}>
                                                {f.shortname}
                                            </Dropdown.Item>
                                        )
                                    })
                                }

                                <Dropdown.Header content='Local' />
                                <Dropdown.Divider />
                                {
                                    _.map(_.filter(facilities, { type: 'local' }), (f, i) => {
                                        return (
                                            <Dropdown.Item
                                                className="facilityOption"
                                                onClick={() => selectFacility(f)}
                                                as={Link}
                                                to={`/ids`}
                                                key={i}>
                                                {f.shortname}
                                            </Dropdown.Item>
                                        )
                                    })
                                }

                            </Dropdown.Menu>
                        </Dropdown>
                }

            </Menu.Menu>

            <Menu.Item>
                <Link to='wx'>
                    <Icon name='cloud' />
                </Link>
            </Menu.Item>

            <Menu.Item>
                <Link to='docs'>
                    <Icon name='book' />
                </Link>
            </Menu.Item>

            <Menu.Item>
                <Icon name='time' />
            </Menu.Item>

            <Menu.Menu position="right">

                <Menu.Item>
                    <UTCClock />
                </Menu.Item>

                <Dropdown item icon='setting'>
                    <Dropdown.Menu>
                        <Dropdown.Header>
                            {
                                cid ?
                                    <Header color='green'>Connected as {cid}</Header>
                                    :
                                    <Header color='red'>Disconnected</Header>
                            }
                        </Dropdown.Header>
                        <Dropdown.Item onClick={() => {
                            if (showNotifications) setNotificationPermission('false');
                            else setNotificationPermission('true');
                        }}>
                            {
                                showNotifications ? 'Disable Push Notifications' : 'Enable Push Notifications'
                            }
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item>
                    <Button color="grey" onClick={() => reset()}>RESET</Button>
                </Menu.Item>

            </Menu.Menu>

        </Container>
        </Menu>
    )

};

const Root = () => {

    return (
        <>

            <IDSMenu />
            <Outlet />

        </>
    )

};

export default function App() {

    const [value, setValue] = useState<Session>({
        cid: null,
        facility: null,
        setFacility: () => { },
    });
    const [facility, setFacility] = useState(null);

    useEffect(() => {

        async function setSession() {

            const cid = await getSessionCID();

            if (!cid) return;

            setValue({
                cid,
                facility,
                setFacility
            });

        }

        setSession();

    }, [facility]);

    return (
        <SessionContext.Provider value={value}>
            <QueryClientProvider client={queryClient}>

                <Routes>

                    <Route path="/" element={<Root />}>

                        <Route index element={<WeatherBriefing />} />

                        <Route path='wx' element={<WX />} />
                        <Route path='docs' element={<Docs />} />

                        <Route path='ids' element={<IDS />}>
                            <Route index element={<SIA />} />
                            <Route path='search' element={<Search />} />
                            <Route path='lookup' element={<Lookup />} />
                            <Route path='charts' element={<Charts />} />
                        </Route>

                    </Route>

                </Routes>

                <ReactQueryDevtools />

            </QueryClientProvider>
        </SessionContext.Provider>
    );

};