import { useAddTMURestriction, useAllTMURestriction, useDeleteTMURestriction } from "@api/gdp";
import { ITMURestriction } from "@zkc/shared";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Icon, Label, Modal, Table } from "semantic-ui-react";
import { SessionContext } from "./Session";
import { Formik } from "formik";
import { Form as FormikForm, SubmitButton, Input, Select, ResetButton } from "formik-semantic-ui-react";

function DelayProgramForm() {

    const { facility } = useContext(SessionContext);
    const { mutateAsync: submit, isSuccess } = useAddTMURestriction();
    const initialValues = {};

    return (

        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => await submit(values)}
        >
            {({ isSubmitting }) => (
                <FormikForm size="large">

                    <Select options={_.map(facility.airports, a => {
                        return {
                            key: a.code,
                            value: a.code,
                            text: a.code
                        }
                    })} name='airport' />

                    <Form.Field inline>
                        <Label size="big">GATE</Label>
                        <Input name="gate" />
                    </Form.Field>

                    <Form.Field inline>
                        <Label size="big">RESTRICTIONS</Label>
                        <Input name="restrictions" />
                    </Form.Field>

                    <Button.Group>
                        <ResetButton>CLEAR</ResetButton>
                        <SubmitButton disabled={isSubmitting}>SUBMIT</SubmitButton>
                    </Button.Group>

                </FormikForm>
            )}
        </Formik>

    )

}

type DelayProgramProps = {
    dp: ITMURestriction
};
function DelayProgram({ dp }: DelayProgramProps) {

    const { mutateAsync } = useDeleteTMURestriction();

    return (
        <Table.Row>
            <Table.Cell>
                {dp.airport}/{dp.gate} {dp.restrictions}
            </Table.Cell>
            <Table.Cell collapsing>
                <Button color="red" icon="trash alternate outline"
                    onClick={() => mutateAsync(dp)}
                />
            </Table.Cell>
        </Table.Row>
    )

}

function DelayPrograms() {

    const { data } = useAllTMURestriction();

    const { cid } = useContext(SessionContext);
    const [restrictions, setTMURestrictions] = useState<ITMURestriction[]>([]);

    useEffect(() => {

        if (_.isNil(cid)) return;

        setTMURestrictions(_.filter(data, (d: ITMURestriction) => {
            return d.cid == null || d.cid === cid;
        }));

    }, [cid, data]);

    return (
        <Table compact>
            <Table.Body>
                {
                    _.map(restrictions, dp => {
                        return <DelayProgram dp={dp} />
                    })
                }
            </Table.Body>
        </Table>
    )

}

export default function TMUModal() {

    const [open, setOpen] = useState(false);

    return (

        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Icon link name='pencil' />}
            size='small'
        >

            <Modal.Content scrolling>
                <DelayPrograms />
                <DelayProgramForm />
            </Modal.Content>

        </Modal>

    )

}