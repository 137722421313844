import { Button } from "semantic-ui-react";
import { Link, To } from "react-router-dom";

type MenuButtonProps = {
    to: To,
    label: string,
    disabled?: boolean
}

export default function MenuButton(props: MenuButtonProps) {

    return (
        <Link to={props.to}>
            <Button primary size="large" disabled={props.disabled}>{props.label}</Button>
        </Link>
    )
}