import { UseQueryResult } from '@tanstack/react-query';

import {
    IFacility,
    IChart,
    IFile
} from '@zkc/shared';
import { useCustomQuery } from '@utils/customqueries';
import { QueryKeys } from '@enums/QueryKeys';

export const useCID = (): UseQueryResult<string, unknown> => {
    return useCustomQuery('https://kcartcc.com/dashboard/controllers/cid', [QueryKeys.getCID]);
};

export const useFacilities = (): UseQueryResult<IFacility[], unknown> => {
    return useCustomQuery('/facilities', [QueryKeys.getFacilities]);
};

export const useFiles = (): UseQueryResult<IFile[], unknown> => {
    return useCustomQuery('/files', [QueryKeys.getFiles]);
};

export const useCharts = (airport: string): UseQueryResult<IChart[], unknown> => {
    return useCustomQuery(`/charts/${airport}`, [QueryKeys.getChart]);
};
