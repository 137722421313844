import { UseQueryResult } from "@tanstack/react-query";
import {
    IMETAR,
    ISIGMETResponse,
    ITAF,
} from '@zkc/shared';
import { useCustomQuery } from "@utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

export const useMETAR = (code: string): UseQueryResult<IMETAR, unknown> => {
    return useCustomQuery(`/metar/${code}`, [QueryKeys.getMETAR, code]);
}

export const useTAF = (code: string): UseQueryResult<ITAF, unknown> => {
    return useCustomQuery(`/taf/${code}`, [QueryKeys.getTAF, code]);
}

export const useSIGMET = (): UseQueryResult<ISIGMETResponse, unknown> => {
    return useCustomQuery(`/sigmets`, [QueryKeys.getSIGMET]);
}