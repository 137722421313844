import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import _ from 'lodash';
import { Container, Grid, Menu } from 'semantic-ui-react';

import { useFiles } from '@api/facility';
import { FileType, IFile } from '@zkc/shared';

type DocGroupProps = {
    key: React.Key | null | undefined,
    name: string,
    docs: Array<IFile> | undefined,
    onClick: Function,
}
function DocGroup({ name, docs, onClick }: DocGroupProps) {

    return (

        <Menu.Item>
            <Menu.Header>{name}</Menu.Header>
            {
                _.map(docs, c => {
                    return (
                        <Menu.Item onClick={() => onClick(c.pdf_path)} name={c.name} />
                    )
                })
            }
        </Menu.Item>

    )

}

type DocProps = {
    file: string | null,
}
function Doc({ file }: DocProps) {

    const [numPages, setNumPages] = useState<number | null>(null);

    return (
        <Document file={{ url: file }} onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}>
            {
                Array.from(new Array(numPages), (_el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))
            }
        </Document>
    )

}

export default function Docs() {

    const [doc, setDoc] = useState<string | null>(null);

    const { data: docs } = useFiles();

    return (

        <Container fluid style={{ height: '90vh' }}>

            <Grid>

                <Grid.Column width={4}>

                    <Menu fluid vertical>
                        {
                            _.map(Object.values(FileType), (value) => {
                                return <DocGroup key={value as string} name={value as string} docs={_.filter(docs, { fileType: value })} onClick={(v: string) => setDoc(v)} />
                            })
                        }
                    </Menu>

                </Grid.Column>

                <Grid.Column stretched width={12}>
                    <Doc file={doc} />
                </Grid.Column>

            </Grid>
        </Container>
    )

}
