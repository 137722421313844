import _ from 'lodash';
import { useState, useEffect } from 'react';
import { Input, Table } from 'semantic-ui-react';

import { IFrequency } from '@zkc/shared';
import frequencyData from '@resources/frequency.json';

export default function Search() {

    const originalList = frequencyData as IFrequency[];

    const [filter, setFilter] = useState<IFrequency[]>(originalList);
    const [value, setValue] = useState('');

    useEffect(() => {

        setFilter(
            _.isEmpty(value) ?
                originalList :
                _.filter(
                    originalList,
                    (f: IFrequency) => {
                        return _.includes([f.callsign, f.freq, f.ident,], value.toLowerCase());
                    }
                )
        );

    }, [value, originalList]);

    return (

            <Table striped celled compact>

                <Table.Header>

                    <Table.Row>
                        <Table.HeaderCell>
                            <Input type='text' icon='search' iconPosition='left' placeholder='Search' onChange={(_e, { value }) => setValue(value)} />
                        </Table.HeaderCell>
                    </Table.Row>

                    <Table.Row>
                        <Table.HeaderCell>Position</Table.HeaderCell>
                        <Table.HeaderCell>Callsign</Table.HeaderCell>
                        <Table.HeaderCell>Frequency</Table.HeaderCell>
                    </Table.Row>

                </Table.Header>

                <Table.Body>
                    {
                        _.map(
                            filter,
                            f => {
                                return (
                                    <Table.Row key={f.callsign}>
                                        <Table.Cell className='text-black'>{f.ident}</Table.Cell>
                                        <Table.Cell className='text-black'>{f.callsign}</Table.Cell>
                                        <Table.Cell className='text-black'>{f.freq}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        )
                    }
                </Table.Body>
            </Table>

    )

}