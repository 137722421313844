import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Table, Input } from "semantic-ui-react";
import { useAllNOTAM } from "@api/notam";
import { INOTAM} from '@zkc/shared';
import { SessionContext } from "./Session";

type NOTAMRowProps = {
    notam: INOTAM,
};

function NOTAMRow({ notam }: NOTAMRowProps) {

    return (
        <Table.Row>
            <Table.Cell />
            <Table.Cell>{notam.airport}</Table.Cell>
            <Table.Cell>{notam.text}</Table.Cell>
            <Table.Cell>{notam.class}</Table.Cell>
            <Table.Cell>{notam.startDate}</Table.Cell>
            <Table.Cell>{notam.endDate}</Table.Cell>
        </Table.Row>
    )

}

export default function NOTAMs() {

    const { data: allNOTAMs } = useAllNOTAM();
    const { facility } = useContext(SessionContext);

    const [notams, setNOTAMs] = useState<INOTAM[]>([]);

    useEffect(() => {

        if (_.isNil(facility) || _.isNil(allNOTAMs)) return;

        let n = _.filter(allNOTAMs, (notam: INOTAM) => {
            
            const airports = _.map(facility.airports, a => {
                return a.code.substring(1)
            });
            return _.includes(airports, notam.airport);

        });

        setNOTAMs(n);

    }, [facility, allNOTAMs]);

    return (

        <Table striped selectable celled>

            <Table.Header>

                <Table.Row>
                    <Table.HeaderCell colSpan='6' textAlign='center'>{facility?.shortname} NOTAMs</Table.HeaderCell>
                </Table.Row>

                <Table.Row>
                    <Table.HeaderCell colSpan='4' />
                    <Table.HeaderCell colSpan='2'>
                        <Input icon='search' iconPosition='left' placeholder='Search keyword' />
                    </Table.HeaderCell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Pin/Unpin</Table.Cell>
                    <Table.HeaderCell>
                        Loc ID
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        NOTAM Text
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Class
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Start Time
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        End Time
                    </Table.HeaderCell>
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(notams, n => {
                        return <NOTAMRow key={n.id} notam={n} />
                    })
                }
            </Table.Body>

        </Table>

    )

}