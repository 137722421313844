import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';

import { worker as mocks } from './mocks/browser';
import {
    IATIS,
    ITMURestriction,
    IPIREP,
    IAreaInfo,
    IOutage,
    INOTAM,
} from '@zkc/shared';
import App, { queryClient } from './App';
import { ENVIRONMENT, pushNotification } from '@utils/config';
import { QueryKeys } from '@enums/QueryKeys';
import { socket } from '@api/websocket';

socket.on('connect', async () => {

    socket.on('recTMURestriction', async (message: ITMURestriction) => {
        queryClient.invalidateQueries([QueryKeys.getTMURestriction]);
        pushNotification(`New TMU Restriction`, `${message.airport}/${message.gate} ${message.status} ${message.restrictions}`);
    });

    socket.on('recATIS', async (message: IATIS) => {
        queryClient.invalidateQueries([QueryKeys.getATIS, message.airport]);
        pushNotification(`${message.airport} Information ${message.code} Current`, `Wind ${message.wind} Altimeter ${message.altimeter}`);
    });

    socket.on('recPIREP', async (_message: IPIREP) => {
        queryClient.invalidateQueries([QueryKeys.getPIREP]);
    });

    socket.on('recAreaInfo', async (message: IAreaInfo) => {
        queryClient.invalidateQueries([QueryKeys.getAreaInfo]);
        pushNotification(`Area Information Posted`, `${message.notice}`);
    });

    socket.on('recOutage', async (_message: IOutage) => {
        queryClient.invalidateQueries([QueryKeys.getOutage]);
        // pushNotification(`Hot Board Message Posted`, `${message.text}`);
    });

    socket.on('recNOTAM', async (_message: INOTAM) => {
        queryClient.invalidateQueries([QueryKeys.getNOTAM]);
        // pushNotification(`NOTAM Posted`, `${message.text}`);
    });

    socket.on('delTMURestriction', (_message: ITMURestriction) => {
        queryClient.invalidateQueries([QueryKeys.getTMURestriction]);
    });

    socket.on('delATIS', (_message: IATIS) => {
        queryClient.invalidateQueries([QueryKeys.getATIS]);
    });

    socket.on('delPIREP', (_message: IPIREP) => {
        queryClient.invalidateQueries([QueryKeys.getPIREP]);
    });

    socket.on('delAreaInfo', (_message: IAreaInfo) => {
        queryClient.invalidateQueries([QueryKeys.getAreaInfo]);
    });

    socket.on('delOutage', (_message: IOutage) => {
        queryClient.invalidateQueries([QueryKeys.getOutage]);
    });

    socket.on('delNOTAM', (_message: INOTAM) => {
        queryClient.invalidateQueries([QueryKeys.getNOTAM]);
    });

});

if (ENVIRONMENT === 'development') {
    mocks.start();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Router>
        <App />
    </ Router>
);
