import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/pirep`, (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    cid: null,
                    id: "1",
                    facility: "ZKC",
                    text: "OJC UA /OV BUM170030/TM 2125/FL280/TP A319/TB CONS LGT/RM ZKC/FDC/CW",
                    time: "2023-02-17T21:25:00Z"
                },
                {
                    cid: null,
                    id: "2",
                    facility: "ZKC",
                    text: "SLO UA /OV VLA260035/TM 2116/FL310/TP B738/TB INTMT MOD CHOP/RM ZKC/FDC/CWCW",
                    time: "2023-02-17T21:16:00Z"
                },
            ])
        );

    }),


];
