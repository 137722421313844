import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/atis/:airport`, (_req, res, ctx) => {

        return res(
            ctx.json({
                "cid": "1012346",
                "airport": "KSTL",
                "code": "A",
                "notams": "",
                "config": [
                    {
                        "runway": "12L",
                        "approach": ["DEP"],
                    },
                    {
                        "runway": "12R",
                        "approach": ["ILS"],
                    },
                    {
                        "runway": "11",
                        "approach": ["ILS"]
                    },
                ],
            })
        );

    }),


];
