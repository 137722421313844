import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Input, Table } from 'semantic-ui-react';

import { IAircraft } from '@zkc/shared';
import aircraftData from '@resources/aircraft.json';

export default function Lookup() {

    const originalList = aircraftData as IAircraft[];

    const [filter, setFilter] = useState<IAircraft[]>(originalList);
    const [value, setValue] = useState('');

    useEffect(() => {

        setFilter(
            _.isEmpty(value) ?
                originalList :
                _.filter(
                    originalList,
                    (f: IAircraft) => {
                        return _.includes([f.name, f.icao], value.toLowerCase());
                    }
                )
        );

    }, [value, originalList]);

    return (

        <Table compact striped celled>

            <Table.Header>

                <Table.Row>
                    <Table.HeaderCell colSpan='7'>
                        <Input type='text' icon='search' iconPosition='left' placeholder='Search' onChange={(_e, { value }) => setValue(value)} />
                    </Table.HeaderCell>
                </Table.Row>

                <Table.Row>
                    <Table.HeaderCell>Designator</Table.HeaderCell>
                    <Table.HeaderCell>Weight Class</Table.HeaderCell>
                    <Table.HeaderCell>SRS Cat</Table.HeaderCell>
                    <Table.HeaderCell>Engines</Table.HeaderCell>
                    <Table.HeaderCell>Engine Type</Table.HeaderCell>
                    <Table.HeaderCell>Climb Rate</Table.HeaderCell>
                    <Table.HeaderCell>Descent Rate</Table.HeaderCell>
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(
                        filter,
                        aircraft => {

                            return (
                                <Table.Row>
                                    <Table.Cell>{aircraft.icao}</Table.Cell>
                                    <Table.Cell>{aircraft.weightClass}</Table.Cell>
                                    <Table.Cell>{aircraft.category.srs}</Table.Cell>
                                    <Table.Cell>{aircraft.engines.number}</Table.Cell>
                                    <Table.Cell>{aircraft.engines.type}</Table.Cell>
                                    <Table.Cell>{aircraft.rate.climb} FPM</Table.Cell>
                                    <Table.Cell>{aircraft.rate.descent} FPM</Table.Cell>
                                </Table.Row>
                            )

                        }
                    )
                }
            </Table.Body>

        </Table>

    )

}