import { useRef, useEffect, useLayoutEffect } from 'react';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CB_URL = process.env.REACT_APP_CB_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const VATUSA_ULS_BASEURL = process.env.REACT_APP_ULS_BASEURL;
export const SECRET = process.env.REACT_APP_SECRET;
export const FACILITY = process.env.REACT_APP_FACILITY;

export function pushNotification(title: string, message: string) {

    const options = {
        silent: true,
        body: message,
        icon: 'https://kcartcc.com/photos/logo.png'
    };

    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") new Notification(title, options);
    else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
            // if (!('permission' in Notification)) Notification.permission = permission;
            if (permission === "granted") new Notification(title, options);
        });
    }

}

export function usePrevious<T>(value: T): T | undefined {

    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;

}

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useInterval(callback: () => void, delay: number | null) {

    const savedCallback = useRef(callback);

    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {

        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);
    
        return () => clearInterval(id);
    
    });

}
