import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/notam`, async (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    "id": "12/208",
                    "airport": "MCI",
                    "class": "Aerodrome",
                    "startDate": "12/29/2022 1854",
                    "endDate": "PERM",
                    "text": "TWY C10 CHANGED TO TWY C9 2212291854-PERM"
                },
                {
                    "id": "02/049",
                    "airport": "STL",
                    "class": "Aerodrome",
                    "startDate": "02/02/2023 2314",
                    "endDate": "02/01/2024 2359",
                    "text": "TWY V2 CLSD 2302022314-2402012359"
                }
            ])
        );

    }),

];
