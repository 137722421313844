import { useState } from "react";

import { useInterval } from '@utils/config';
import { DateTime } from "luxon";

export default function UTCClock() {

    const [time, setTime] = useState<string>();

    useInterval(() => {

        let d: DateTime = DateTime.utc();

        setTime(d.toLocaleString(DateTime.TIME_24_WITH_SECONDS));

    }, 1000);

    return <div className='clock'>{time}</div>

}