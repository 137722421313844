import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Accordion, Grid, Header, Icon, Segment, SemanticICONS, Table } from 'semantic-ui-react';

import { usePrevious } from '@utils/config';
import { IAirport, IATIS } from '@zkc/shared';
import { useMETAR } from '@api/weather';
import { useATIS } from '@api/atis';
import { LocalRunwayConfig, RadarRunwayConfig } from './RunwayConfig';
import { LocalWind } from './Wind';
import { LocalAltimeter, RadarAltimeter } from './Altimeter';
import BrakingAction from './BrakingAction';
import { RadarMETAR } from './METAR';
import { SessionContext } from './Session';

type RadarATISProps = {
    index: number,
    airport: string,
    activeIndex: number,
    onClick: Function,
}
function RadarATIS({ index, airport, activeIndex, onClick }: RadarATISProps) {

    const [blink, setBlink] = useState<boolean>(false);
    const [thisAtis, setAtis] = useState<IATIS | null>(null);
    const prevAtis = usePrevious<IATIS>(thisAtis);

    const [iconName, setIconName] = useState<SemanticICONS>('angle double up');

    const { data: weather, refetch: fetchWX } = useMETAR(airport);
    const { data: atis } = useATIS(airport);

    useEffect(() => {

        let str = 'angle double ';
        str += activeIndex === index ? 'up' : 'down';
        setIconName(str as SemanticICONS);

    }, [activeIndex, index]);

    useEffect(() => {

        fetchWX();

        if (!atis) {
            setAtis(null);
        } else {

            if (prevAtis == null || prevAtis.code !== atis.code) setBlink(true);
            setAtis(atis);

        }

    }, [atis, fetchWX, prevAtis, weather]);

    return (
        <>
            <Accordion.Title index={index} active={activeIndex === index} onClick={(_e, titleProps) => onClick(titleProps.index)} >

                <Segment.Group horizontal>
                    <Segment />
                    <Segment textAlign='center'>{airport} ATIS</Segment>
                    <Segment textAlign='right'><Icon circular name={iconName} /></Segment>
                </Segment.Group>

            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>

                <Grid stretched celled columns={2}>

                    <Grid.Row>

                        <Grid.Column width={4} verticalAlign='middle' textAlign='center'>
                            <Header size='huge'>{atis?.code}</Header>
                        </Grid.Column>

                        <Grid.Column width={12}>
                            <RadarRunwayConfig config={atis?.config} />
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row>

                        <RadarAltimeter altimeter={weather?.alt_hg} />
                        <RadarMETAR metar={weather?.raw_text} />

                    </Grid.Row>

                </Grid>

            </Accordion.Content>
        </>
    )

}

type LocalATISProps = {
    airport: string,
}
export function LocalATIS({ airport }: LocalATISProps) {

    const [blink, setBlink] = useState<boolean>(false);
    const [wind, setWind] = useState<string>('');
    const [thisAtis, setAtis] = useState<IATIS | null>(null);
    const prevAtis = usePrevious<IATIS>(thisAtis);

    const { data: metar, refetch: fetchMETAR } = useMETAR(airport);
    const { data: atis } = useATIS(airport);

    useEffect(() => {

        fetchMETAR();

        if (!atis) {
            setAtis(null);
        } else {

            if (prevAtis == null || prevAtis.code !== atis.code) setBlink(true);
            setAtis(atis);

        }

        if (_.isNil(metar)) return;

        const wind_dir = metar.wind;
        const wind_vel = metar.wind_vel;
        // const wind_gust = metar.wind_gust_kt;

        let wind;
        if ((wind_vel < 3)) wind = "CALM";
        // else if (wind_gust !== "") wind = `${wind_dir} / ${wind_vel} G${wind_gust}`;
        else if (wind_dir === "") wind = `VRB @ ${wind_vel}`;
        else wind = `${wind_dir} / ${wind_vel}`;

        setWind(wind);

    }, [atis, fetchMETAR, prevAtis, metar]);

    return (

        <>

            <Table textAlign='center'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{airport} ATIS</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Header size='huge'>{atis?.code}</Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <LocalRunwayConfig config={atis?.config} />
            <LocalWind wind={wind} />
            <LocalAltimeter altimeter={metar?.alt_hg} />
            <BrakingAction runwayCC={4} />

        </>

    )

}

export function RadarATISList() {

    const { facility } = useContext(SessionContext);

    const [activeIndex, setActiveIndex] = useState<number>(0);

    function onAccordionClick(index: number) {

        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex as number);

    }

    return (
        <Accordion styled>
            {
                _.map(facility.airports, (a: IAirport, i: number) => {
                    return <RadarATIS key={i} index={i} airport={a.code} activeIndex={activeIndex} onClick={(idx: number) => onAccordionClick(idx)} />
                })
            }
        </Accordion>
    )

}