import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/areainfo`, (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    cid: null,
                    id: '1',
                    facility: 'ZKC',
                    notice: 'Nulla finibus nunc tincidunt nisi porta, id iaculis erat molestie'
                }
            ])
        )
    }),


];
