import { Container } from "semantic-ui-react";

export default function Blank() {

    return (

        <Container className='my-auto'>
            <h2 className="text-black">No Facility Selected</h2>
        </Container>

    )

}