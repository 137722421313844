export interface IConfigs {
    [key: string]: {
        configs: Array<IConfig>,
    }
};

export enum ApproachType {
    ILS = "ILS",
    TCN = "TCN",
    VIS = "VIS",
    VOR = "VOR",
    DEP = "DEP",
};

export interface IRunwayConfig {
    runway: string,
    approach: Array<keyof typeof ApproachType>,
};

export interface IConfig {
        name: string,
        runways: Array<IRunwayConfig>,
};
