export enum QueryKeys {
    getCID = 'getCID',
    getFacilities = 'getFacilities',
    getFiles = 'getFiles',
    getChart = 'getChart',
    getAreaInfo = 'getAreaInfo',
    getATIS = 'getATIS',
    getTMURestriction = 'getGDP',
    // getHotBoard = 'getHotBoard',
    getOutage = 'getOutage',
    getNOTAM = 'getNOTAM',
    getPIREP = 'getPIREP',
    getMETAR = 'getMETAR',
    getTAF = 'getTAF',
    getSIGMET = 'getSIGMET',
};