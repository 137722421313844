import { UseQueryResult } from "@tanstack/react-query";
import { IPIREP } from '@zkc/shared';
import { useCustomMutation, useCustomQuery } from "@utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

export const useAddPIREP = () => {
    return useCustomMutation('POST', `/pirep`, [QueryKeys.getPIREP]);
};

export const useAllPIREP = (): UseQueryResult<IPIREP[], unknown> => {
    return useCustomQuery(`/pirep`, [QueryKeys.getPIREP]);
};

export const usePIREP = (value: IPIREP): UseQueryResult<IPIREP, unknown> => {
    return useCustomQuery(`/pirep/${value.id}`, [QueryKeys.getPIREP, value.id]);
};

// export const useUpdatePIREP = () => {
//     return useMutation(
//         async (value: IPIREP) => {
//             try {
//                 await api.patch(`/pirep/${value.id}`, value);
//             } catch (err) {
//                 return;
//             }
//         }, {
//             onSuccess: () => {
//                 queryClient.invalidateQueries([QueryKeys.getPIREP], { refetchActive: true, refetchInactive: true });
//             }
//         }
//     )
// };

// export const useDeletePIREP = () => {
//     return useMutation(
//         async (value: IPIREP) => {
//             try {
//                 await api.delete(`/pirep/${value.id}`);
//             } catch (err) {
//                 return;
//             }
//         }, {
//             onSuccess: () => {
//                 queryClient.invalidateQueries([QueryKeys.getPIREP], { refetchActive: true, refetchInactive: true });
//             }
//         }
//     )
// };
