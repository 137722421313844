import ReactPlayer from 'react-player/file';
import { Container } from 'semantic-ui-react';

export default function WeatherBriefing() {

    const url = 'https://www.weather.gov/media/zkc/ZKCPDWB.mp4';

    return (

        <Container fluid>
            <ReactPlayer controls url={url} width='50vw' />
        </Container>

    )

}