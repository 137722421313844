import { UseQueryResult } from "@tanstack/react-query";

import { ITMURestriction } from '@zkc/shared';
import { useCustomMutation, useCustomQuery } from "@utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

export const useAddTMURestriction = () => {
    return useCustomMutation('POST', `/gdp`, [QueryKeys.getTMURestriction]);
};

export const useAllTMURestriction = (): UseQueryResult<ITMURestriction[], unknown> => {
    return useCustomQuery('/gdp', [QueryKeys.getTMURestriction]);
};

// export const useTMURestriction = (value: ITMURestriction): UseQueryResult<ITMURestriction, unknown> => {
//     return useCustomQuery(`/gdp/${value.airport}`, [QueryKeys.getTMURestriction, value.airport]);
// };

// export const useUpdateTMURestriction = () => {
//     return useCustomMutation('PATCH', `/gdp`, [QueryKeys.getTMURestriction]);
// };

export const useDeleteTMURestriction = () => {
    return useCustomMutation('DELETE', `/gdp`, [QueryKeys.getTMURestriction]);
};
