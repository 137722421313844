export enum ChartType {
    apd = 'APD',
    dp = 'DP',
    star = 'STAR',
    iap = 'IAP',
};

export interface IChart {
    state: string;
    state_full: string;
    city: string;
    airport_name: string;
    military: string;
    faa_ident: string;
    icao_ident: string;
    chart_seq: string;
    chart_code: string;
    chart_name: string;
    pdf_name: string;
    pdf_path: string;
};

export interface IChartResponse {
    [key: string]: IChart[];
}