import { Table, Header } from "semantic-ui-react";

type WindProps = {
    wind: string,
};

export function LocalWind({ wind }: WindProps) {

    return (

        <Table textAlign='center'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Wind</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><Header as='h2'>{wind}</Header></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

    )

}