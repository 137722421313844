import { Table, Container } from "semantic-ui-react";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";

import { useAllTMURestriction } from "@api/gdp";
import TMUModal from "./TMUModal";
import { ITMURestriction } from "@zkc/shared";
import { SessionContext } from "./Session";

type DelayProgramProps = {
    dp: ITMURestriction
};
function DelayProgram({ dp }: DelayProgramProps) {

    return (
        <Table.Row>
            <Table.Cell textAlign="center">
                {dp.airport}/{dp.gate} {dp.restrictions}
            </Table.Cell>
        </Table.Row>
    )

}

export default function DelayPrograms() {

    const { data: allDPs } = useAllTMURestriction();
    const { facility } = useContext(SessionContext);

    const [dps, setDPs] = useState<ITMURestriction[]>([]);

    useEffect(() => {

        if (_.isNil(facility) || _.isNil(allDPs)) return;

        let d = _.filter(allDPs, (dp: ITMURestriction) => {

            const airports = _.map(facility.airports, a => {
                return a.code;
            });
            return _.includes(airports, dp.airport) || dp.facility === facility.shortname;

        });

        setDPs(d);

    }, [facility, allDPs])

    return (

        <Container>
            <Table singleLine>

                <Table.Header>

                    <Table.Row>

                        <Table.HeaderCell>
                            <TMUModal />
                        </Table.HeaderCell>

                        <Table.HeaderCell>Ground Delay Programs</Table.HeaderCell>

                    </Table.Row>

                </Table.Header>

                <Table.Body>
                    {
                        _.map(dps, d => {
                            return <DelayProgram key={d.id} dp={d} />
                        })
                    }
                </Table.Body>

            </Table>
        </Container>

    )

}