import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/metar/:code`, async (_req, res, ctx) => {

        return res(
            ctx.json({
                "station_id": "KMCI",
                "raw": "KMCI 262253Z 14011KT 1SM R19R/6000VP6000FT BR OVC003 08/08 A2966 RMK AO2 SFC VIS 1 1/4 SLP047 T00830078",
                "temp": "8.3",
                "dewpoint": "7.8",
                "wind": "140",
                "wind_vel": "11",
                "visibility": "1.0",
                "alt_hg": "29.66",
                "alt_mb": "1004.7",
                "wx": "BR",
                "auto_report": "true",
                "sky_conditions": [{ "coverage": "OVC", "base_agl": "300" }],
                "category": "LIFR",
                "report_type": "METAR",
                "time_of_obs": "2023-02-26T22:53:00Z",
            })
        );

    }),

    rest.get(`${API_URL}/taf/:code`, async (_req, res, ctx) => {

        return res(
            ctx.json({
                'station_id': 'KMCI',
                'raw': 'KMCI 162320Z 1700/1724 32012G20KT P6SM SCT015 BKN045 FM170200 33011KT P6SM FEW250 FM171600 22010KT P6SM SKC',
                'issue_time': '2023-02-16T23:20:00Z',
                'valid_time': '2023-02-17T00:00:00Z - 2023-02-18T00:00:00Z',
                'line_by_line': ["KMCI 162320Z 1700/1724 32012G20KT P6SM SCT015 BKN045", "FM170200 33011KT P6SM FEW250", "FM171600 22010KT P6SM SKC"]
            })
        );

    }),

    rest.get(`${API_URL}/sigmets`, async (_req, res, ctx) => {

        return res(
            ctx.json({})
        );

    }),

];
