import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Layer, MapProvider, Map, Source } from 'react-map-gl';
import { Container } from 'semantic-ui-react';
import axios from 'axios';

import { useSIGMET } from '@api/weather';
import { useInterval } from '@utils/config';

type WeatherRadarProps = {
    radar_type: string,
    showWeather: boolean,
};
function WeatherRadar({ radar_type, showWeather }: WeatherRadarProps) {

    const [show, setShow] = useState(showWeather);
    const [tiles, setTiles] = useState<string[]>([]);

    async function fetchWX() {

        const { data } = await axios.get('https://api.rainviewer.com/public/weather-maps.json');

        if (_.isNil(data?.radar) || _.isNil(data?.radar?.past)) return null;

        return data.radar.past[data.radar.past.length - 1].path;

    }

    useEffect(() => {

        let isMounted = true;

        if (radar_type === 'off' || !showWeather) return;

        if (isMounted) fetchWX().then(result => {

            if (_.isNil(result)) return;

            let t = `https://tilecache.rainviewer.com${result}/1024/{z}/{x}/{y}/${radar_type}/0_1.png`;

            setShow(false);
            setTiles([t]);
            setShow(true);

        });

        return () => { isMounted = false; }

    }, [radar_type, showWeather]);

    useInterval(async () => {

        if (radar_type === 'off' || !showWeather) return;

        let result = await fetchWX();
        let t = `https://tilecache.rainviewer.com${result}/1024/{z}/{x}/{y}/${radar_type}/0_1.png`;

        setShow(false);
        setTiles([t]);
        setShow(true);

    }, 10 * 60 * 1000);

    return (showWeather && show) && radar_type !== 'off' && _.isEmpty(tiles) ?
        <Source type='raster'
            // coordinates={[
            //     [180, 90],
            //     [-180, 90],
            //     [-180, -90],
            //     [180, -90]
            // ]}
            tileSize={1024}
            tiles={tiles}
        >
            <Layer type='raster' paint={{
                "raster-opacity": 0.666
            }} />
        </Source>
        : null

}

export default function WX() {

    const { data: sigmets } = useSIGMET();

    return (

        <Container style={{ padding: '10px' }} fluid>

            <MapProvider>

                <Map
                    id="wx"
                    style={{ width: '90vw', height: '90vh' }}
                    mapStyle={process.env.REACT_APP_MAPBOX_URL}
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                >

                    <WeatherRadar showWeather={true} radar_type="6" />

                </Map>

            </MapProvider>

        </Container>

    )

}
