import { UseQueryResult } from "@tanstack/react-query";
import { INOTAM } from '@zkc/shared';
import { useCustomQuery } from "@utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

// export const useAddNOTAM = () => {
//     return useCustomMutation<INOTAM>('POST', `/notam`, [QueryKeys.getNOTAM]);
// };

export const useAllNOTAM = (): UseQueryResult<INOTAM[], unknown> => {
    return useCustomQuery<INOTAM>(`/notam`, [QueryKeys.getNOTAM]);
};

export const useNOTAM = (value: string): UseQueryResult<INOTAM, unknown> => {
    return useCustomQuery<INOTAM>(`/notam/${value}`, [QueryKeys.getNOTAM, value]);
};

// export const useUpdateNOTAM = () => {
//     return useCustomMutation<INOTAM>('PATCH', `/notam`, [QueryKeys.getNOTAM]);
// };

// export const useDeleteNOTAM = () => {
//     return useCustomMutation<INOTAM>('DELETE', `/notam`, [QueryKeys.getNOTAM]);
// };
