import { createContext } from 'react';
// import Cookies from 'universal-cookie';
// import { default as jwt } from 'jsonwebtoken';

// import {VATUSA_ULS_BASEURL, FACILITY} from '../utils/config';
import Axios from 'axios';
import { IFacility } from '@zkc/shared';

// const cookies = new Cookies();

// export const getSessionCookie = () => {

//     const sessionCookie = cookies.get('auth');

//     if (!sessionCookie || sessionCookie === "") return null;
//     else return jwt.verify(sessionCookie, process.env.REACT_APP_SECRET);

// }

export async function getSessionCID() {

    const { data } = await Axios.get<string>('https://kcartcc.com/dashboard/controllers/cid');
    return data;

}

export type Session = {
    cid: string | null,
    facility: IFacility | null,
    setFacility: Function,
};

export const SessionContext = createContext<Session>({
    cid: null,
    facility: null,
    setFacility() {},
});

// export const login = async () => {
//     window.location.href = `${VATUSA_ULS_BASEURL}/login?fac=${FACILITY}&dev=1&url=2`;
// }
