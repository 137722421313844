import { Button, Grid, Menu } from "semantic-ui-react";
import MenuButton from "./MenuButton";
import PIREPModal from "./PIREPModal";

export default function IDSFooter() {

    return (
        <Menu borderless compact fixed="bottom">

            {/* <Container> */}

                <Grid container stackable columns={5}>

                    <Grid.Column>
                        <MenuButton label="Home" to='' />
                    </Grid.Column>

                    <Grid.Column>
                        <Button primary disabled>Messages</Button>
                    </Grid.Column>

                    <Grid.Column>
                        <MenuButton label="Charts" to='charts' />
                    </Grid.Column>

                    <Grid.Column>
                        <MenuButton label="Search" to='search' />
                    </Grid.Column>

                    <Grid.Column>
                        <MenuButton label="Lookup" to='lookup' />
                    </Grid.Column>

                    <Grid.Column>
                        <PIREPModal />
                    </Grid.Column>

                </Grid>

            {/* </Container> */}

        </Menu>
    )

}
