import { handlers as facilityHandlers } from "./facility"
import { handlers as weatherHandlers } from "./weather";
import { handlers as atisHandlers } from "./atis";
import { handlers as notamHandlers } from "./notams";
import { handlers as pirepHandlers } from "./pirep";
import { handlers as gdpHandlers } from "./gdp";
import { handlers as areainfoHandlers } from "./areainfo";
import { handlers as outageHandlers } from "./outage";

export const handlers = [
    ...facilityHandlers,
    ...weatherHandlers,
    ...atisHandlers,
    ...notamHandlers,
    ...pirepHandlers,
    ...gdpHandlers,
    ...areainfoHandlers,
    ...outageHandlers,
];