import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`https://kcartcc.com/dashboard/controllers/cid`, async (req, res, ctx) => {

        return res(
            ctx.body("1012346"),
        );

    }),

    rest.get(`${API_URL}/facilities`, async (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    "shortname": "ZKC",
                    "type": "enroute",
                    "airports": [
                        {
                            "code": "KSTL",
                        },
                        {
                            "code": "KMCI",
                        },
                        {
                            "code": "KTUL",
                        },
                        {
                            "code": "KICT",
                        },
                    ],
                },
                {
                    "shortname": "T75",
                    "type": "terminal",
                    "airports": [
                        {
                            "code": "KSTL",
                        },
                        {
                            "code": "KCPS",
                        },
                        {
                            "code": "KSUS",
                        },
                        {
                            "code": "KALN",
                        },
                        {
                            "code": "KBLV",
                        },
                    ],
                },
                {
                    "shortname": "KC",
                    "type": "terminal",
                    "airports": [
                        {
                            "code": "KMCI",
                        },
                        {
                            "code": "KMKC",
                        },
                        {
                            "code": "KOJC",
                        },
                        {
                            "code": "KIXD",
                        },
                        {
                            "code": "KSTJ",
                        },
                    ],
                },
                {
                    "shortname": "MCI",
                    "type": "local",
                    "airports": [
                        {
                            "code": "KMCI",
                        },
                    ],
                },
                {
                    "shortname": "STL",
                    "type": "local",
                    "airports": [
                        {
                            "code": "KSTL",
                        },
                    ],
                },
            ])
        );

    }),

    rest.get(`${API_URL}/files`, async (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    "fileType": "SOP",
                    "name": "KC TRACON SOP",
                    "path": "https://kcartcc.com/storage/files/MCI%20SOP.pdf",
                },
                {
                    "fileType": "LOA",
                    "name": "ZAU Chicago LOA",
                    "path": "https://kcartcc.com/storage/files/ZAU_ZKC_LOA%20Final%20Signed.pdf",
                },
                {
                    "fileType": "QR",
                    "name": "ZKC TRACONs Cheat Sheet",
                    "path": "https://kcartcc.com/storage/files/ZKC%20TRACONs%20Cheat%20Sheet_1570824567.pdf",
                },
            ])
        );

    }),

    rest.get(`${API_URL}/charts/:airport`, async (_req, res, ctx) => {

        return res(
            ctx.json([
                { "state": "MO", "state_full": "MISSOURI", "city": "KANSAS CITY", "volume": "NC-3", "airport_name": "KANSAS CITY INTL", "military": "N", "faa_ident": "MCI", "icao_ident": "KMCI", "chart_seq": "70000", "chart_code": "APD", "chart_name": "AIRPORT DIAGRAM", "pdf_name": "00780AD.PDF", "pdf_path": "https://charts.aviationapi.com/AIRAC_230126/00780AD.PDF" },
                { "state": "MO", "state_full": "MISSOURI", "city": "KANSAS CITY", "volume": "NC-3", "airport_name": "KANSAS CITY INTL", "military": "N", "faa_ident": "MCI", "icao_ident": "KMCI", "chart_seq": "90100", "chart_code": "DP", "chart_name": "CHIEF SEVEN", "pdf_name": "00780CHIEF.PDF", "pdf_path": "https://charts.aviationapi.com/AIRAC_230126/00780CHIEF.PDF" },
                { "state": "MO", "state_full": "MISSOURI", "city": "KANSAS CITY", "volume": "NC-3", "airport_name": "KANSAS CITY INTL", "military": "N", "faa_ident": "MCI", "icao_ident": "KMCI", "chart_seq": "30000", "chart_code": "STAR", "chart_name": "BRAYMER SEVEN", "pdf_name": "00780BRAYMER.PDF", "pdf_path": "https://charts.aviationapi.com/AIRAC_230126/00780BRAYMER.PDF" },
                { "state": "MO", "state_full": "MISSOURI", "city": "KANSAS CITY", "volume": "NC-3", "airport_name": "KANSAS CITY INTL", "military": "N", "faa_ident": "MCI", "icao_ident": "KMCI", "chart_seq": "50750", "chart_code": "IAP", "chart_name": "ILS OR LOC RWY 01L", "pdf_name": "00780IL1L.PDF", "pdf_path": "https://charts.aviationapi.com/AIRAC_230126/00780IL1L.PDF" },
            ])
        );

    }),

];
