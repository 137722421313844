import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/gdp`, (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    cid: null,
                    id: '1',
                    facility: 'ZKC',
                    airport: 'KSTL',
                    gate: 'ALL',
                    status: 'active',
                    restrictions: 'CLSD'
                }
            ])
        );

    }),


];
