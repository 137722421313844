import { IRunwayConfig } from "@zkc/shared";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Table, Header, Grid } from "semantic-ui-react";

type RunwayConfigProps = {
    config: IRunwayConfig,
}

export function RadarRunwayConfig({ config }: RunwayConfigProps) {

    const [approach, setApproach] = useState<string>('');
    const [arriving, setArriving] = useState<string[]>([]);
    const [departing, setDeparting] = useState<string[]>([]);

    useEffect(() => {

        if (_.isNil(config)) return;

        let arrive = _.filter(config, (c: IRunwayConfig) => {
            return !_.includes(c.approach, 'DEP');
        });

        setApproach(arrive[0].approach);

        setArriving(
            _.map(
                arrive
                , c => { return c.runway }
            )
        );

        setDeparting(
            _.map(
                _.filter(config, (c: IRunwayConfig) => {
                    return _.includes(c.approach, 'DEP');
                }), c => { return c.runway }
            )
        );

    }, [config]);

    return (

        <Grid celled columns={3}>

            <Grid.Row stretched>
                <Grid.Column>Arriving</Grid.Column>
                <Grid.Column>
                    {
                        _.join(arriving, ' ')
                    }
                </Grid.Column>
                <Grid.Column>
                    {approach}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>Departing</Grid.Column>
                <Grid.Column>
                    {
                        _.join(departing, ' ')
                    }
                </Grid.Column>
                <Grid.Column />
            </Grid.Row>

        </Grid>

    )

}

export function LocalRunwayConfig({ config }: RunwayConfigProps) {

    return (
        <Table celled textAlign='center'>
            <Table.Body>
                {
                    _.map(config, c => {
                        return (
                            <Table.Row>
                                <Table.Cell>
                                    <Header size='large'>
                                        {c.runway}
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header size='large'>
                                        {
                                            _.join(c.approach, ' ')
                                        }
                                    </Header>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })
                }
            </Table.Body>
        </Table>
    )

}
