import { useContext } from "react";
import { Outlet } from "react-router-dom";
import _ from "lodash";

import IDSFooter from "@components/IDSFooter";
import { SessionContext } from "@components/Session";

export default function IDS() {

    const { facility } = useContext(SessionContext);

    return (

        <>

            <Outlet />            
            {
                _.isNil(facility) ? null : <IDSFooter />
            }

        </>

    )

}
