import { useAllOutage } from "@api/outage";
import { IOutage } from "@zkc/shared";
import _ from "lodash";
import { useContext } from "react";
import { Container, Table } from "semantic-ui-react";
import { SessionContext } from "./Session";

type OutageProps = {
    outage: IOutage
};
function Outage({ outage }: OutageProps) {

    return (
        <Table.Row>
            <Table.Cell>{outage.text}</Table.Cell>
        </Table.Row>
    )

}

export default function EquipmentOutages() {

    const { facility } = useContext(SessionContext);

    const { data: outages } = useAllOutage();

    return (
        <Container>
            <Table singleLine>

                <Table.Header fullWidth>

                    <Table.Row>

                        <Table.HeaderCell textAlign="center">{facility?.shortname} Equipment Outages</Table.HeaderCell>

                    </Table.Row>

                </Table.Header>

                <Table.Body>
                    {
                        _.map(outages, o => {
                            return <Outage key={o.id} outage={o} />
                        })
                    }
                </Table.Body>

            </Table>
        </Container>
    )
}