import { IAirport } from "@interfaces/IAirport.interfrace";

export enum FacilityType {
    local = "local",
    terminal = "terminal",
    enroute = "enroute",
};

export interface IFacility {
    shortname: string,
    type: FacilityType,
    airports: IAirport[],
};