import { Table } from "semantic-ui-react";
import { useAllPIREP } from "@api/pirep";
import { IPIREP } from "@zkc/shared";
import _ from "lodash";

type PIREPRowProps = {
    pirep: IPIREP,
};
function PIREPRow({ pirep }: PIREPRowProps) {

    return (
        <Table.Row>
            <Table.Cell />
            <Table.Cell>{pirep.text}</Table.Cell>
        </Table.Row>
    )

}

export default function PIREPs() {

    const { data: pireps } = useAllPIREP();

    return (

        <Table striped celled>

            <Table.Header>

                <Table.Row>
                    <Table.HeaderCell colSpan='2' textAlign="center">PIREPS</Table.HeaderCell>
                </Table.Row>

                <Table.Row>
                    <Table.HeaderCell>Pin/Unpin</Table.HeaderCell>
                    <Table.HeaderCell>PIREPS</Table.HeaderCell>
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(pireps, p => {
                        return <PIREPRow pirep={p} />
                    })
                }
            </Table.Body>

        </Table>

    )

}