export enum FileType {
    sop = 'SOP',
    loa = 'LOA',
    qr = 'QR',
};

export interface IFile {
    fileType: FileType,
    name: string,
    path: string,
};