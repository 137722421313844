import { Table, Header } from "semantic-ui-react";

type CCtoBAMap = {
    [id: number]: string;
};
const RwyCCtoBA: CCtoBAMap = {
    6: 'Good',
    5: 'Good',
    4: 'Good to Medium',
    3: 'Medium',
    2: 'Medium to Poor',
    1: 'Poor',
    0: 'Nil',
};

type BrakingActionProps = {
    runwayCC: number;
};
export default function BrakingAction({ runwayCC }: BrakingActionProps) {

    const ba: string = RwyCCtoBA[runwayCC];

    return (

        <Table textAlign='center'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Braking Action</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row className={ba.toLowerCase()}>
                    <Table.Cell>
                        <Header size='huge'>{ba}</Header>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

    )

}