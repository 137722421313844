import { useState, useContext, useEffect } from "react";
import { useAddAreaInfo, useAreaInfo, useDeleteAreaInfo } from "@api/areainfo";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { Formik } from 'formik';
import { Form as FormikForm, SubmitButton, TextArea, ResetButton } from "formik-semantic-ui-react";
import { SessionContext } from "./Session";
import { IAreaInfo } from "@zkc/shared";
import _ from "lodash";

function AreaNotesForm() {

    const { mutateAsync: submit, isSuccess } = useAddAreaInfo();
    const initialValues = {};

    return (

        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => await submit(values)}
        >
            {({ isSubmitting }) => (
                <FormikForm size="large">

                    <TextArea name="notice" />

                    <Button.Group>
                        <ResetButton>CLEAR</ResetButton>
                        <SubmitButton disabled={isSubmitting}>SUBMIT</SubmitButton>
                    </Button.Group>

                </FormikForm>
            )}
        </Formik>

    )

}

type AreaNoteProps = {
    info: IAreaInfo
};
function AreaNote({ info }: AreaNoteProps) {

    const { mutateAsync } = useDeleteAreaInfo();

    return (
        <Table.Row>
            <Table.Cell>
                {info.notice}
            </Table.Cell>
            <Table.Cell collapsing>
                <Button color="red" icon="trash alternate outline"
                    onClick={() => mutateAsync(info)}
                />
            </Table.Cell>
        </Table.Row>
    )

}

function AreaNotes() {

    const { data } = useAreaInfo();

    const { cid } = useContext(SessionContext);
    const [areainfo, setAreaInfo] = useState<IAreaInfo[]>([]);

    useEffect(() => {

        if (_.isNil(cid)) return;

        setAreaInfo(_.filter(data, d => {
            return d.cid == null || d.cid === cid;
        }));

    }, [cid, data]);

    return (
        <Table compact>
            <Table.Body>
                {
                    _.map(areainfo, a => {
                        return <AreaNote info={a} />
                    })
                }
            </Table.Body>
        </Table>
    )

}

export default function AreaModal() {

    const [open, setOpen] = useState(false);

    return (

        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Icon link name='pencil' />}
            size='small'
        >

            <Modal.Content scrolling>
                <AreaNotes />
                <AreaNotesForm />
            </Modal.Content>

        </Modal>

    )

}