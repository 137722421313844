import { Container, Grid, Header, Table } from "semantic-ui-react";

type METARProps = {
    metar: string,
};

export function LocalMETAR({ metar }: METARProps) {

    return (

        <Container>
            <Table>

                <Table.Header fullWidth>

                    <Table.Row textAlign="center">
                        <Table.HeaderCell>METAR</Table.HeaderCell>
                    </Table.Row>

                </Table.Header>

                <Table.Body>

                    <Table.Row>
                        <Table.Cell>
                            <Header as='h4'>{metar}</Header>
                        </Table.Cell>
                    </Table.Row>

                </Table.Body>

            </Table>
        </Container>

    )
}

export function RadarMETAR({ metar }: METARProps) {

    return (

        <Grid.Column width={12}>{metar}</Grid.Column>

    )

}