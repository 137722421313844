import { UseQueryResult } from "@tanstack/react-query";

import { IAreaInfo } from "@zkc/shared";
import { useCustomMutation, useCustomQuery } from "../utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

const ENDPOINT = '/areainfo';

export const useAddAreaInfo = () => {
    return useCustomMutation<IAreaInfo>('POST', `${ENDPOINT}`, [QueryKeys.getAreaInfo]);
};

export const useAreaInfo = (): UseQueryResult<IAreaInfo | IAreaInfo[], unknown> => {
    return useCustomQuery<IAreaInfo>(`${ENDPOINT}`, [QueryKeys.getAreaInfo]);
};

export const useDeleteAreaInfo = () => {
    return useCustomMutation<IAreaInfo>('DELETE', `${ENDPOINT}`, [QueryKeys.getAreaInfo]);
};