import { UseQueryResult } from "@tanstack/react-query";

import { IATIS } from "@zkc/shared";
import { useCustomQuery } from "@utils/customqueries";
import { QueryKeys } from "@enums/QueryKeys";

// export const useAddATIS = () => {
//     return useCustomMutation<IATIS>('POST', `/atis`, [QueryKeys.getATIS]);
// };

// export const useAllATIS = (): UseQueryResult<IATIS[], unknown> => {
//     return useCustomQuery<IATIS>(`/atis`, [QueryKeys.getATIS]);
// };

export const useATIS = (value: string): UseQueryResult<IATIS, unknown> => {
    return useCustomQuery(`/atis/${value}`, [QueryKeys.getATIS, value]);
};

// export const useUpdateATIS = () => {
//     return useCustomMutation('PATCH', `/atis`, [QueryKeys.getATIS]);
// };

// export const useDeleteATIS = () => {
//     return useCustomMutation('DELETE', `/atis`, [QueryKeys.getATIS]);
// };
