import { Table, Header, Grid } from "semantic-ui-react";

type AltimeterProps = {
    altimeter: string,
};

export function LocalAltimeter({ altimeter }: AltimeterProps) {

    return (

        <Table textAlign='center'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Altimeter</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><Header size="large">{altimeter}</Header></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

    )

}

export function RadarAltimeter({ altimeter }: AltimeterProps) {

    return (

        <Grid.Column width={4} verticalAlign='middle' textAlign='center'>
            <Header size='large'>{altimeter}</Header>
        </Grid.Column>

    )

}