import { QueryKey, useMutation, useQuery } from "@tanstack/react-query";
import { Method } from "axios";
import { api } from "@api/axios";
import { queryClient } from "../App";
import { IAreaInfo, IATIS, IOutage, INOTAM, IPIREP, ITMURestriction } from '@zkc/shared';

export function useCustomMutation<T extends IATIS | ITMURestriction | IPIREP | IAreaInfo | IOutage | INOTAM>(method: Method, url: string, queryKeys: QueryKey) {

    return useMutation({
        mutationFn: async function (data: T) {
            await api.request({
                method,
                url,
                data
            });
        },
        onMutate: async function (newValue: T) {

            await queryClient.cancelQueries({ queryKey: queryKeys });

            const previousValues = queryClient.getQueriesData(queryKeys);
            queryClient.setQueryData(queryKeys, (old: any) => [...old, newValue]);

            return { previousValues };

        },
        onError: function (_err, _newValue, context) {
            queryClient.setQueryData(queryKeys, context?.previousValues);
        },
        onSettled: function () {
            queryClient.invalidateQueries({ queryKey: queryKeys });
        },
    });

}

export function useCustomQuery<T extends IATIS | ITMURestriction | IPIREP | IAreaInfo | IOutage | INOTAM>(url: string, queryKeys: QueryKey) {

    return useQuery({
        queryKey: queryKeys,
        queryFn: async function () {
            const { data } = await api.get<T>(url);
            return data;
        },
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

}
