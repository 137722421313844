import { rest } from "msw";

import { API_URL } from "@utils/config";

export const handlers = [

    rest.get(`${API_URL}/outage`, (_req, res, ctx) => {

        return res(
            ctx.json([
                {
                    id: "1",
                    text: "Donec leo libero, accumsan eget"
                }
            ])
        );

    }),


];
