export interface IPIREP {
    cid: string | null,
    id: string,
    facility: string,
    text: string,
    time: Date,
};

export enum ReportType {
    UA = 'UA - Routine',
    UUA = 'UUA - Urgent',
};

export enum SkyCover {
    NA = 'N/A',
    CLR = 'CLR',
    FEW = 'FEW',
    SCT = 'SCT',
    BKN = 'BKN',
    OVC = 'OVC',
};

export enum Turbulence {
    NA = 'N/A',
    LGT = 'LGT',
    MDT = 'MDT',
    SVR = 'SVR',
    EXTRM = 'EXTRM',
};

export enum IcingType {
    NA = 'N/A',
    CLR = 'CLR',
    RIME = 'RIME',
    MXD = 'MXD',
};

export enum IcingLevel {
    NA = 'N/A',
    TRC = 'TRC',
    LGT = 'LGT',
    MDT = 'MDT',
    SVR = 'SVR',
};
