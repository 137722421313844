import { useState } from 'react';
import { Container, Form, Grid, Menu } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf';
import _ from 'lodash';

import { useCharts } from '@api/facility';
import { ChartType, IChart } from '@zkc/shared';

type ChartGroupProps = {
    key: React.Key | null | undefined,
    name: string,
    charts: Array<IChart> | undefined,
    onClick: Function,
}
function ChartGroup({ name, charts, onClick }: ChartGroupProps) {

    return (

        <Menu.Item>
            <Menu.Header>{name}</Menu.Header>
            {
                _.map(charts, c => {
                    return (
                        <Menu.Item onClick={() => onClick(c.pdf_path)} name={c.chart_name} />
                    )
                })
            }
        </Menu.Item>

    )

}

type ChartProps = {
    file: string | null,
}
function Chart({ file }: ChartProps) {

    const [numPages, setNumPages] = useState<number | null>(null);

    return (
        <Document file={{ url: file }} onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}>
            {
                Array.from(new Array(numPages), (_el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))
            }
        </Document>
    )

}

export default function Charts() {

    const [airport, setAirport] = useState('');
    const [chart, setChart] = useState<string | null>(null);

    const { refetch: search, data: charts } = useCharts(airport);

    return (

        <Container fluid style={{ height: '90vh' }}>

            <Grid>

                <Grid.Column width={4}>

                    <Menu fluid vertical>

                        <Menu.Item>
                            <Form onSubmit={() => search()}>
                                <Form.Group>
                                    <Form.Input icon='search' placeholder='Airport...' value={airport} onChange={(_e, { value }) => setAirport(value)} />
                                    <Form.Button content='Search' onClick={() => search() } />
                                </Form.Group>
                            </Form>
                        </Menu.Item>

                        {
                            _.map(Object.values(ChartType), value => {
                                return <ChartGroup key={value as string} name={value as string} charts={_.filter(charts, { chart_code: value })} onClick={(v: string) => setChart(v)} />
                            })
                        }

                    </Menu>

                </Grid.Column>

                <Grid.Column stretched width={12}>
                    <Chart file={chart} />
                </Grid.Column>

            </Grid>
        </Container>

    )

}
